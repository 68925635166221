import React from 'react';
import LighthouseForm from './LighthouseForm';


const Home = () => {

  return (
    <div>
    <div className="form-container py-5">
      
      <div className='p-2'>
       <div className="text-center mb-4">
        <h1>Lighthouse Report Generator</h1>
        <h2>Generate Lighthouse Report®</h2>
        <p>Grade your website in seconds. Then learn how to improve it for free.</p>
      </div>
      <LighthouseForm />
     
      </div>
    </div>
    </div>
  );
};

export default Home;