import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Link, useNavigate } from 'react-router-dom'; 
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL || '';

function Datalist() {
  const [showModal, setShowModal] = useState(false);
  const [Search, setSearch] = useState('');
  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState('');
  const limit = 10;

  const [Datalistname, setDatalistName] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); 

  const savedatalist = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please upload a CSV file.');
      return;
    }

    const formData = new FormData();
    formData.append('Datalistname', Datalistname);
    formData.append('File', file);

    try {
      const response = await axios.post(apiUrl + '/api/datalist', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data) {
        alert('Data list inserted successfully!');
        await fetchReports();
        resetForm();
        setShowModal(false);
      }
    } catch (error) {
      console.error('Data list insertion unsuccessful!', error);
      alert('Error not inserted');
    }
  };

  useEffect(() => {
    if (Search.trim()) {
      searchReports();
    } else {
      fetchReports();
    }
  }, [Search, page]);

  const fetchReports = async () => {
    try {
      const response = await axios.get(apiUrl + '/api/datalist', {
        params: {
          limit,
          page
        }
      });
      
      const groupedData = groupByDatalistId(response.data.data);
      setReports(groupedData);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error fetching reports', error);
    }
  };

  const groupByDatalistId = (data) => {
    const grouped = {};
    data.forEach(item => {
      if (!grouped[item.datalist_id]) {
        grouped[item.datalist_id] = item;
      }
    });
    return Object.values(grouped);
  };

  const handleDatalistClick = (datalistId) => {
    navigate(`/datalist-details/${datalistId}`); 
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const searchReports = async () => {
    try {
      const response = await axios.get(apiUrl + '/api/searchdatalist', {
        params: {
          Search,
          limit,
          page
        }
      });
      const groupedData = groupByDatalistId(response.data.data || []);
      setReports(groupedData);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error searching reports', error);
    }
  };

  const Delete = (id) => {
    if (window.confirm('Are you sure you want to delete this data?')) {
      axios.delete(apiUrl + `/api/datalist/${id}`)
        .then(response => {
          setReports(reports.filter(report => report.datalist_id !== id));
          fetchReports();
        })
        .catch(error => {
          console.error('There was an error deleting the record!', error);
        });
    }
  };

  const resetForm = () => {
    setDatalistName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDownload = async () => {
    const url = process.env.PUBLIC_URL + '/assets/csv/seo.csv';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'seo.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="Website-list">
        <div className="col">
          <Navbar />
        </div>
        <div>
          <div className="pt-4"></div>
          <Sidebar />
          <div className="pt-5"></div>
          <div className="main-content">
            <div className="page-header">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="title">
                    <h4>Data List</h4>
                  </div>
                  <nav aria-label="breadcrumb" role="navigation" className='pt-4'>
                    <div className="row">
                      <div className="col">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            Menu
                          </li>
                          <li className="breadcrumb-item active text-danger" aria-current="page">
                            Data List
                          </li>
                        </ol>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className='form-control'
                          placeholder='Search by Keywords'
                          value={Search}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-primary float-end" onClick={() => setShowModal(true)}> Add / Import Data list</button>
                </div>
              </div>
            </div>
            <div className="p-2"></div>
            
            <div className={`modal fade bd-example-modal-lg ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} id="newsmpt" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <form onSubmit={savedatalist}>
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">New Data List</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Data List Name</label>
                        <input 
                          type="text" 
                          name='Datalist_name' 
                          id="Datalist_name" 
                          className="form-control" 
                          placeholder='' 
                          value={Datalistname} 
                          onChange={(e) => setDatalistName(e.target.value)} 
                          required 
                        />
                      </div>
                      <div className="mb-2">
                        <div className="row">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Choose CSV File</label> 
                          <div className="col-6">
                            <input type="file" id="fileInput" name="file" accept=".csv" ref={fileInputRef} onChange={handleFileChange} required/>
                          </div>
                          <div className="col-6">
                            <a className='pointer' onClick={handleDownload}>Download Sample file</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                      <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="page-body">
              <div className="titel-hrader table-responsive table-responsive-scrollbar-top">
              </div>
              <table className="table table-hover vw-100">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">DataList name</th>
                    <th scope="col">Webmaster name</th>
                    <th scope="col">Website url</th>
                    <th scope="col">Email Id</th>
                    <th scope="col">Contact Number</th>
                    <th>Uploaded date</th>
                    <th>Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">No Data List</td>
                    </tr>
                  ) : (
                    reports.map((report) => (
                      <tr key={report.datalist_id}>
                        <td>{report.datalist_id}</td>
                        <td>
                          <span 
                            className="pointer text-primary" 
                            onClick={() => handleDatalistClick(report.datalist_id)}
                          >
                            {report.datalist_name}
                          </span>
                        </td>
                        <td>{report.webmaster_name}</td>
                        <td>{report.url}</td>
                        <td>{report.email}</td>
                        <td>{report.contact_number}</td>
                        <td>{report.datalist_created_at || report.created_at}</td>
                        <td>
                          <div className="row m-0">
                            <div className="col-md-6">
                              <button 
                                type="button" 
                                className="btn btn-danger pt-1" 
                                onClick={() => Delete(report.datalist_id)}
                              >
                                <i className="bi bi-trash3-fill"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <nav aria-label="...">
                  {limit < total && (
                    <ul className="pagination">
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index + 1} className={`page-item ${page === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => setPage(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datalist;