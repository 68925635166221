
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
const apiUrl = process.env.REACT_APP_API_URL || '';

function DatalistDetails() {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [datalistName, setDatalistName] = useState('');
  const limit = 10;

  useEffect(() => {
    if (search.trim()) {
      searchRecords();
    } else {
      fetchRecords();
    }
  }, [page, search]);

  const fetchRecords = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/datalist-records/${id}`, {
        params: { page, limit }
      });
      setRecords(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
      setDatalistName(response.data.datalist_name || ''); 
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const searchRecords = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/datalist-records-search/${id}`, {
        params: { search, page, limit }
      });
      setRecords(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error searching records:', error);
    }
  };

  const handleDelete = (recordId) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      axios.delete(`${apiUrl}/api/datalist-record/${recordId}`)
        .then(() => {
          setRecords(records.filter(record => record.report_id !== recordId));
          fetchRecords(); 
        })
        .catch(error => {
          console.error('Error deleting record:', error);
        });
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); 
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Datalist Details - {datalistName} (ID: {id})</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation" className="pt-4">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/data-list">Datalist</Link>
                  </li>
                  <li className="breadcrumb-item active text-danger" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control float-end"
                placeholder="Search records"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="page-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Webmaster Name</th>
                <th>Website URL</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Status</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {records.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">No Records Found</td>
                </tr>
              ) : (
                records.map((record) => (
                  <tr key={record.report_id}>
                    <td>{record.report_id}</td>
                    <td>{record.webmaster_name}</td>
                    <td>{record.url}</td>
                    <td>{record.email}</td>
                    <td>{record.contact_number}</td>
                    <td>{record.status}</td>
                    <td>{record.report_created_at}</td>
                   
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-center">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index + 1} className={`page-item ${page === index + 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => setPage(index + 1)}>
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatalistDetails;