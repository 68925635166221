import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL || '';

const CampaignSchedule = () => {
  const [Campaignname, setCompaignname] = useState('');
  const [Smtp, setSmtp] = useState('');
  const [Emailtemplet, setEmailtemplet] = useState('');
  const [loading, setLoading] = useState(false);
  const [Status, setStatus] = useState('');
  const [DatalistId, setDatalistId] = useState('');
  const [CampaignDate, setCampaignDate] = useState('');
  const [CampaignTime, setCampaignTime] = useState('');

  const [Datalist, setDatalist] = useState([]);
  const [Smtplist, setSmtpList] = useState([]);
  const [Emaillist, setEmaillist] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({ id: '', campaign_name: '', status: '' });

  const [Search, setSearch] = useState('');
  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const limit = 10;

  const fetchReports = async () => {
    try {
      const response = await axios.get(apiUrl + '/api/campaign', {
        params: { limit, page },
      });
      console.log('Raw API response:', response.data); // Log raw response
      setReports(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error fetching reports', error);
    }
  };

  const savedata = async (e) => {
    e.preventDefault();
    setLoading(true);

    const normalizedDate = new Date(CampaignDate).toISOString().split('T')[0]; // Normalize date

    try {
      const response = await axios.post(apiUrl + '/api/campaign', {
        Campaignname,
        DatalistId,
        Smtp,
        Emailtemplet,
        Status,
        CampaignDate: normalizedDate,
        CampaignTime,
      });

      if (response.data) {
        alert('Campaign data inserted successfully!');
        await fetchReports();
        resetForm();
        setShowModal(false);
      }
    } catch (error) {
      console.error('Campaign data insertion unsuccessful!', error);
      alert('Error not inserted');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const searchReports = async () => {
    try {
      const response = await axios.get(apiUrl + '/api/searchcampaign', {
        params: { Search, limit, page },
      });
      setReports(response.data.data || []);
      setTotalPages(response.data.totalPages);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error searching reports', error);
    }
  };

  const Delete = (id) => {
    if (window.confirm('Are you sure you want to delete this data?')) {
      axios
        .delete(apiUrl + `/api/campaign/${id}`)
        .then(() => {
          setReports(reports.filter((report) => report.id !== id));
        })
        .catch((error) => {
          console.error('There was an error deleting the record!', error);
        });
    }
  };

  const resetForm = () => {
    setCompaignname('');
    setDatalistId('');
    setEmailtemplet('');
    setSmtp('');
    setStatus('');
    setCampaignDate('');
    setCampaignTime('');
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(apiUrl + `/api/campaign/${currentRecord.id}`, currentRecord);
      setReports(reports.map((report) =>
        report.id === currentRecord.id ? currentRecord : report
      ));
      setUpdateModal(false);
    } catch (error) {
      console.error('There was an error updating the record!', error);
    }
  };

  const handleShowModal = (record) => {
    setCurrentRecord(record);
    setUpdateModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({ ...prevRecord, [name]: value }));
  };

  const handleCloseModal = () => {
    setUpdateModal(false);
    setCurrentRecord({ id: '', campaign_name: '', status: '' });
  };

  useEffect(() => {
    if (Search.trim()) {
      searchReports();
    } else {
      fetchReports();
      fetchDatalist();
      fetchSmtplist();
      fetchemaillist();
    }
  }, [Search, page]);

  const fetchDatalist = async () => {
    const response = await axios.get(apiUrl + '/api/datalist/list');
    setDatalist(response.data);
  };

  const fetchSmtplist = async () => {
    const response = await axios.get(apiUrl + '/api/smtp/list');
    setSmtpList(response.data);
  };

  const fetchemaillist = async () => {
    const response = await axios.get(apiUrl + '/api/emailtemplet/list');
    setEmaillist(response.data);
  };

  return (
    <div>
      <div className="Website-list">
        <div className="col">
          <Navbar />
        </div>
        <div>
          <div className="pt-4"></div>
          <Sidebar />
          <div className="pt-5"></div>
          <div className="main-content">
            <div className="page-header">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="title">
                    <h4>Campaign Schedule</h4>
                  </div>
                  <nav aria-label="breadcrumb" role="navigation" className="pt-4">
                    <div className="row">
                      <div className="col">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">Menu</li>
                          <li className="breadcrumb-item active text-danger" aria-current="page">
                            Campaign Schedule
                          </li>
                        </ol>
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by Keywords"
                          value={Search}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={() => setShowModal(true)}
                  >
                    Add Campaign Data
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`modal fade bd-example-modal-lg ${showModal ? 'show' : ''}`}
              style={{ display: showModal ? 'block' : 'none' }}
              id="newsmpt"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden={!showModal}
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <form onSubmit={savedata}>
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        New Campaign Data
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col">
                          <div className="mb-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Campaign Name
                            </label>
                            <input
                              type="text"
                              name="Compaign_name"
                              id="Compaign_name"
                              className="form-control"
                              placeholder=""
                              value={Campaignname}
                              onChange={(e) => setCompaignname(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-2">
                            <label htmlFor="campaign_date" className="form-label">
                              Campaign Date
                            </label>
                            <input
                              type="date"
                              name="campaign_date"
                              id="campaign_date"
                              className="form-control"
                              value={CampaignDate}
                              onChange={(e) => setCampaignDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-2">
                            <label htmlFor="campaign_time" className="form-label">
                              Campaign Time
                            </label>
                            <input
                              type="time"
                              name="campaign_time"
                              id="campaign_time"
                              className="form-control"
                              value={CampaignTime}
                              onChange={(e) => setCampaignTime(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Data List Name
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={DatalistId}
                              onChange={(e) => setDatalistId(e.target.value)}
                              required
                            >
                              <option value="">Select Datalist</option>
                              {Datalist.map((datalist) => (
                                <option key={datalist.id} value={datalist.id}>
                                  {datalist.datalist_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Status
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={Status}
                              onChange={(e) => setStatus(e.target.value)}
                              required
                            >
                              <option value="">Select status</option>
                              <option value="Active">Active</option>
                              <option value="Deactive">Deactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Email Template
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={Emailtemplet}
                              onChange={(e) => setEmailtemplet(e.target.value)}
                              required
                            >
                              <option value="">Select SMTP</option>
                              {Emaillist.map((emaillist) => (
                                <option key={emaillist.id} value={emaillist.id}>
                                  {emaillist.email_templet_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              SMTP
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={Smtp}
                              onChange={(e) => setSmtp(e.target.value)}
                              required
                            >
                              <option value="">Select SMTP</option>
                              {Smtplist.map((smtplist) => (
                                <option key={smtplist.id} value={smtplist.id}>
                                  {smtplist.mail_mailer}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Save upto 60 sec' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {updateModal && (
              <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <form onSubmit={handleUpdate}>
                      <div className="modal-header">
                        <h5 className="modal-title">Update Campaign Data</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={handleCloseModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="campaign_name" className="form-label">
                            Campaign Name
                          </label>
                          <input
                            type="text"
                            name="campaign_name"
                            id="campaign_name"
                            className="form-control"
                            value={currentRecord.campaign_name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            name="status"
                            id="status"
                            className="form-select"
                            value={currentRecord.status}
                            onChange={handleChange}
                            required
                          >
                            <option value="" disabled>
                              Select status
                            </option>
                            <option value="Active">Active</option>
                            <option value="Deactive">Deactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleCloseModal}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            <div className="p-2"></div>
            <div className="page-body">
              <div className="titel-hrader table-responsive table-responsive-scrollbar-top"></div>
              <table className="table table-hover vw-100">
                <thead>
                  <tr>
                    <th scope="col">Campaign Date</th>
                    <th scope="col">Campaign Time</th>
                    <th scope="col">Campaign Name</th>
                    <th scope="col">Data List Name</th>
                    <th scope="col">Email Template Name</th>
                    <th scope="col">SMTP Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Campaign Data
                      </td>
                    </tr>
                  ) : (
                    reports.map((report) => (
                      <tr key={report.id}>

                        <td>{new Date(report.campaign_date).toISOString().split('T')[0]}</td>
                        <td>{report.campaigntime}</td>
                        <td>{report.campaign_name}</td>
                        <td>{report.datalist_name}</td>
                        <td>{report.email_templet_name}</td>
                        <td>{report.mail_mailer}</td>
                        <td
                          className={
                            report.status === 'Active' ? 'text-success' : 'text-danger'
                          }
                        >
                          {report.status}
                        </td>
                        <td>
                          <div className="row m-0">
                            <div className="col-md-3">
                              <Link
                                type="button"
                                className="btn btn-success"
                                to={`/website-list/${report.id}`}
                              >
                                <i className="bi bi-eye"></i>
                              </Link>
                            </div>
                            <div className="col-md-3">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleShowModal(report)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </div>
                            <div className="col-md-3">
                              <button
                                type="button"
                                className="btn btn-danger pt-1"
                                onClick={() => Delete(report.id)}
                              >
                                <i className="bi bi-trash3-fill"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              <nav aria-label="...">
                {limit < total && (
                  <ul className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li
                        key={index + 1}
                        className={`page-item ${page === index + 1 ? 'active' : ''}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSchedule;